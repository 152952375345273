window.addEventListener('load', function(){
	let header = document.querySelector('.header')
	let burger = document.querySelector('.header__burger')
	let menu = document.querySelector('.header__nav')
	let itemsMenu = document.querySelectorAll('.header__nav-link');
	let footerMenu = document.querySelectorAll('.footer__nav-item a');
	let footerNav = document.querySelector('.footer__nav-items')
	let hMenu = 100;


	//Menu
	burger.addEventListener('click', function(){
		this.classList.toggle('active');
		menu.classList.toggle('active');
	});

	menu.addEventListener('click', function(e){
		if(e.target.classList.contains('header__nav-link')){
			e.preventDefault();
			
			let link = e.target;
			scrollToId(link.hash, hMenu);

			menu.querySelector('.header__nav-link.active').classList.remove('active');
			link.classList.add('active');
		}
	});

	footerNav.addEventListener('click', function(e){
		if(e.target.classList.contains('footer__nav-link')){
			e.preventDefault();
			
			let link = e.target;
			scrollToId(link.hash, hMenu);

			footerNav.querySelector('.footer__nav-link.active').classList.remove('active');
			link.classList.add('active');
		}
	});

	//Scroll
	document.addEventListener('scroll', function() {
		if(scrollY > 5) {
			header.classList.add('sticky');
		} else {
			header.classList.remove('sticky');
		}
	});
	
	document.addEventListener('scroll', function(e) {	
		let pos = window.scrollY;
		
		for(let i = itemsMenu.length-1; i>=0; i--) {
			let link = itemsMenu[i];
			let header = document.querySelector(link.hash)
			
			if(pos > ((getCoords(header).top) - (window.innerHeight / 1.7)))  {
				menu.querySelector('.active').classList.remove('active')
				link.classList.add('active')
				break;
			}
		}
	});

	//Video
	let src = ['video/step_1.mp4', 'video/step_2.mp4', 'video/step_3.mp4', 'video/step_4.mp4']
	let section = document.querySelector('.server')
	let video = document.querySelector('.server__video video')
	let videoLink = document.querySelectorAll('.server__item')

	let flag = false
	document.addEventListener('scroll', function() {
		if(!flag && scrollY >= getCoords(section).top / 2) {
			video.play()
			flag = true;
		}
	});

	let i = 0;

	video.addEventListener('ended', function() {
		if(i < src.length-1) {
			i++	
			video.setAttribute('src', src[i])
			document.querySelector('.server__item.active').classList.remove('active')
			videoLink[i].classList.add('active')
			video.play()
		}
	});

	for (let i = 0; i < videoLink.length; i++) {
		videoLink[i].addEventListener("click", function() {
			video.pause()
			document.querySelector('.server__item.active').classList.remove('active')
			this.classList.add('active')

			video.setAttribute('src', src[i])
			video.play();
		});
	}

	//Why Us
	let tabsLink = document.querySelectorAll('.why-us__item')

	for(let i = 0; i <= tabsLink.length; i++) {
		tabsLink[i].addEventListener('click', function() {
			let itemId = this.getAttribute('data-link')

			document.querySelector('.why-us__item.active').classList.remove('active');
			document.querySelector('.why-us__slide.active').classList.remove('active');
			this.classList.add('active')
			
			document.querySelector('[data-tab="'+itemId+'"]').classList.add('active');
		});
	}
});


/* Functions */ 
function getCoords(elem) {
	let box = elem.getBoundingClientRect();

	return {
		top: box.top + scrollY
	}  
}

function scrollToId(id, h){
	let target = document.querySelector(id);

	if(target !== null){
		let pos = getCoords(target).top - h;

		window.scrollTo({
			top: pos,
			behavior: "smooth"
		});
	}
}

function scrollToId(id, h){
	let target = document.querySelector(id);

	if(target !== null){
		let pos = getCoords(target).top - h;

		window.scrollTo({
			top: pos,
			behavior: "smooth"
		});
	}
}